<script>
    import BatteryLevel from './BatteryLevel.vue'
    import ConnectedStatus from './ConnectedStatus.vue'

    export default {
        name: 'HeadsetsList',
        components: {
            BatteryLevel,
            ConnectedStatus,
        },

        props: {
            max: {
                type: Number,
                default: null,
            },
            headsets: {
                type: Array,
                required: true,
            },
            modelValue: {
                type: Array,
                default: [],
            },
            disabled: {
                type: Boolean,
                default: false,
            },

            columns: {
                type: Array,
                default: 1,
            },
            withBorder: {
                type: Boolean,
                default: false,
            },
            rows: {
                type: Object,
                default: () => ({}),
            },
            noHeadset: {
                type: String,
                default: 'headsets._no_headset',
            },
        },
        computed: {
            value: {
                get() {
                    return this.modelValue.map((h) => this.headsets.indexOf(h))
                },
                set(value) {
                    if (this.max && value.length > this.max) {
                        //splice the to much elements from start
                        value.splice(0, value.length - this.max)
                    }
                    let headsets = this.headsets.filter((h, index) =>
                        value.includes(index)
                    )
                    this.$emit('update:modelValue', headsets)
                },
            },
        },
        watch: {
            max() {
                this.value = this.value
            },
            headsets() {
                this.value = this.value.filter((i) => i < this.headsets.length)
            },
        },
    }
</script>
<template>
    <ra-list
        v-model="value"
        :disabled="disabled"
        class="headset-list"
        multiple
    >
        <ra-list-item
            v-for="(h, i) of headsets"
            v-if="headsets.length"
            :key="'headset-' + i"
            class="headset-list-item"
        >
            <template v-slot="props">
                <ra-stack
                    alignement="center"
                    direction="horizontal"
                    gap="0"
                    height="75px"
                    v-bind="rows"
                    @click="$emit('click:item', $event, h)"
                >
                    <ra-box
                        v-for="(col, i) of columns"
                        :class="i == 0 || !withBorder ? '' : 'border-left'"
                        :width="col?.width || null"
                        :flex="col?.flex || null"
                        centered-content
                        height="100%"
                        padding=", 4"
                        v-bind="col.props"
                    >
                        <slot
                            :name="col.name"
                            v-bind="{ headset: h, column: col, ...props }"
                        />
                    </ra-box>
                </ra-stack>
            </template>
        </ra-list-item>
        <div v-else-if="noHeadset">
            <ra-typo
                color="primary-lighten-5"
                italic
                padding=", 5"
            >
                {{ $t(noHeadset) }}
            </ra-typo>
        </div>
    </ra-list>
</template>
<style lang="scss">
    .headset-list-item {
        > .ra-stack {
            padding: 0;
            justify-content: space-between;

            .ra-box {
                width: 100%;
            }
        }

        img {
            max-width: 50px;
            height: 100%;
        }
    }
</style>
