<template>
    <StartFormationLayout
        :close-drawer="isTablet"
        :currentStep="currentStep"
        @back="handleBack"
    >
        <template #asideTitle>
            <web>
                <ra-typo
                    bold
                    padding=", 5"
                    uppercase
                    variant="title"
                >
                    {{ $t('headsets.pilotage.headsets_list.title') }}
                </ra-typo>
            </web>
            <tablet>
                <ra-box padding=",6">
                    <ra-stack
                        alignement="center"
                        direction="horizontal"
                        gap="5"
                    >
                        <b class="chip bg-primary">1</b>
                        <div gap="0">
                            <ra-typo variant="title">
                                {{
                                    $t(
                                        'headsets.pilotage.steps.step0.title.content3'
                                    )
                                }}
                                <ra-typo
                                    bold
                                    variant="title"
                                >
                                    {{
                                        $t(
                                            'headsets.pilotage.steps.step0.title.content3_huge'
                                        )
                                    }}
                                </ra-typo>
                            </ra-typo>
                        </div>
                    </ra-stack>
                </ra-box>
            </tablet>
        </template>

        <template #aside>
            <start-formation-headset-list
                :headsets-app-driven="headsetsAppDriven"
            />
        </template>

        <ra-stack
            bg="white"
            gap="4"
            padding="8"
            rounded
        >
            <select-formation
                v-if="!selectedFormation"
                :formations="translatedFormationsReadyForDrive"
                :packs="translatedPacksReadyForDrive"
                @click:formation="
                    (formation) => (selectedFormation = formation)
                "
            />
            <template v-if="selectedFormation">
                <select-module
                    v-if="!editSettings"
                    v-model:modules-selected="selectedModule"
                    :formation="selectedFormation"
                    @start-formation="startFormation"
                />
                <set-formation-settings
                    v-else
                    v-model:settings="settings"
                />

                <ra-stack
                    direction="horizontal"
                    distribution="side-spacing"
                    gap="8"
                    mobile-swap
                    wrap
                >
                    <template v-if="!editSettings">
                        <ra-btn
                            bg="primary-gradient"
                            width="230px"
                            @click="startFormation"
                        >
                            <ra-typo
                                semi
                                variant="subtitle"
                            >
                                {{
                                    $t(
                                        'headsets.pilotage.steps.step1.start_formation'
                                    )
                                }}
                            </ra-typo>
                        </ra-btn>
                        <!--<ra-btn-->
                        <!--  bg="tablet-light"-->
                        <!--  @click="editSettings = true"-->
                        <!--  <v-icon size="large"> mdi-cog</v-icon>-->
                        <!--</ra-btn>-->
                    </template>
                    <ra-btn
                        v-else
                        bg="primary-gradient"
                        width="230px"
                        @click="editSettings = false"
                    >
                        <ra-typo
                            semi
                            variant="subtitle"
                        >
                            {{
                                $t(
                                    'headsets.pilotage.steps.step1.settings_formation.validate'
                                )
                            }}
                        </ra-typo>
                    </ra-btn>
                </ra-stack>
            </template>
        </ra-stack>
    </StartFormationLayout>
</template>

<script>
    import ApplicationLayout from '@layouts/global/ApplicationLayout.vue'
    import BatteryLevel from '@components/Headsets/BatteryLevel.vue'
    import HeadsetsList from '@components/Headsets/HeadsetsList.vue'
    import ConnectedStatus from '@components/Headsets/ConnectedStatus.vue'
    import PacksFilter from '@components/Packs/PacksFilter.vue'
    import FormationGrid from '@components/Formations/FormationGrid.vue'
    import SelectFormation from '@components/Headsets/StartFormation/SelectFormation.vue'

    import { mapState } from 'pinia'
    import { useMDMStore } from '@store/mdm.js'
    import SelectModule from '@components/Headsets/StartFormation/SelectModule.vue'
    import PilotageSettings from '@components/Headsets/PilotageSettings.vue'
    import SetFormationSettings from '@components/Headsets/StartFormation/SetFormationSettings.vue'
    import StartFormationProgress from '@components/Headsets/StartFormation/StartFormationProgress.vue'
    import { useAppStore } from '@store/app.js'
    import StartFormationHeadsetList from '@components/Headsets/StartFormation/StartFormationHeadsetList.vue'
    import StartFormationLayout from '@layouts/MDM/StartFormationLayout.vue'
    import { translateRecursively } from '@utils/translateRecursively.js'
    import { useLanguageStore } from '@store/lang.js'
    import FormationCollection from '@libs/Collections/Formation.js'
    import { HeadsetRoutesNames } from '@routes/generators/Headset.names.js'

    export default {
        name: 'Start-Session',
        components: {
            StartFormationLayout,
            StartFormationHeadsetList,
            StartFormationProgress,
            SetFormationSettings,
            PilotageSettings,
            SelectModule,
            SelectFormation,
            FormationGrid,
            PacksFilter,
            ConnectedStatus,
            HeadsetsList,
            BatteryLevel,
            ApplicationLayout,
        },
        computed: {
            ...mapState(useMDMStore, [
                'headsetsAppDriven',
                'formationReadyForDrive',
            ]),
            ...mapState(useLanguageStore, ['currentLocale']),
            ...mapState(useAppStore, ['isTablet']),
            translatedFormationsReadyForDrive() {
                return new FormationCollection(
                    [...this.formationReadyForDrive].map((f) =>
                        translateRecursively(f, this.currentLocale)
                    )
                )
            },
            translatedPacksReadyForDrive() {
                return this.translatedFormationsReadyForDrive.packs
            },
            currentStep() {
                let res = this.selectedFormation ? 2 : 1
                return window.IS_TABLET ? res : res - 1
            },
        },

        data() {
            return {
                selectedFormation: null,
                selectedModule: [],
                editSettings: false,
                settings: null,
            }
        },

        mounted() {
            if (this.headsetsAppDriven.length === 0) {
                this.goBack()
            }

            if ('formation_id' in this.$route.query) {
                this.selectedFormation =
                    this.translatedFormationsReadyForDrive.findById(
                        this.$route.query.formation_id
                    )
            }
        },

        methods: {
            handleBack() {
                if (this.selectedFormation) {
                    this.selectedFormation = null
                    this.selectedModule = []
                    this.settings = null
                    this.editSettings = null
                } else {
                    this.goBack()
                }
            },
            goBack() {
                if (this.$route.query?.back) {
                    this.$router.push(this.$route.query.back)
                } else {
                    this.$router.push('/my-headsets')
                }
            },
            startFormation() {
                if (!this.selectedFormation) return
                for (let headset of this.headsetsAppDriven) {
                    headset.WSsend({
                        type: 'startFormation',
                        modules_ids: this.selectedModule.length
                            ? this.selectedModule.map((m) => m.id)
                            : null,
                        settings: this.settings,
                        formation_id: this.selectedFormation.id,
                    })
                }

                this.$router.push({
                    name: HeadsetRoutesNames.drive,
                    query: this.$route.query,
                })
            },
        },

        watch: {
            editSettings(value) {
                if (this.settings == null && value) {
                    this.settings = {
                        interactionMode: 'controller',
                        controllerPreferences: 'right',
                        language: 'fr',
                        launchTraining: 'use',
                        quiz: {
                            vr: true,
                            el: true,
                        },
                    }
                }
            },
        },
    }
</script>
